import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { CheckoutSection } from '../../components/sections';
import { CheckoutProvider } from '../../hooks/checkout-context';
import { CountryCode } from '../../utils/constants';

const CheckoutPage = (): JSX.Element => {
  return (
    <CheckoutProvider>
      <Layout hideHeader countryCode={CountryCode.SG}>
        <SEO title="Checkout" />
        <CheckoutSection />
      </Layout>
    </CheckoutProvider>
  );
};

export default CheckoutPage;
